<template>
  <div class="info-flexible-combo">
    <KTCodePreview v-bind:title="title">
      <template v-slot:preview>
        <b-row v-for="row of notSupportedAlias" :key=row>
          <b-col>
            <div
              class="warning-header"
              role="alert"
              style="width: 100%"
            >
              <div class="left-alert">
                <inline-svg
                  src="media/svg/icons/other/warning-circle.svg"
                  width="24"
                  height="24"
                ></inline-svg>
              </div>
              <div class="right-alert text-dark">
                <p>
                  <span class="text-primary">Alias {{row}} chưa đạt chỉ tiêu</span>
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="!notSupportedAlias.length && mainModel.id">
          <b-col>
            <div
              class="success-header"
              role="alert"
              style="width: 100%"
            >
              <div class="left-alert">
                <inline-svg
                  src="media/svg/icons/other/success.svg"
                  width="24"
                  height="24"
                ></inline-svg>
              </div>
              <div class="right-alert text-dark">
                <span>Dealsheet mã {{mainModel.code}} đã đạt chỉ tiêu</span>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="main">
          <b-col cols="3">
            <b-form-group class="required-control">
              <label>Tên chương trình</label>
              <b-form-input
                size="sm"
                v-model="mainModel.name"
                placeholder="Nhập tên"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group class="required-control">
              <label>Mã chương trình</label>
              <b-form-input
                size="sm"
                v-model="mainModel.code"
                placeholder="Nhập mã"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group class="required-control">
              <label>Thời gian</label>
              <div class="d-flex justify-content-center align-items-center">
                <date-picker
                  placeholder="Từ ngày"
                  class="form-control-sm"
                  :config="dpConfigs.date"
                  v-model="mainModel.startDate"
                ></date-picker>
                <span class="ml-1 mr"></span>
                <date-picker
                  placeholder="Đến ngày"
                  class="form-control-sm"
                  :config="dpConfigs.date"
                  v-model="mainModel.endDate"
                ></date-picker>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group>
              <label>Trạng thái</label>
              <b-form-select
                size="sm"
                class="select-style"
                v-model="mainModel.isActive"
                :options="[
                {
                  id: 1,
                  name: 'Hoạt động',
                },
                {
                  id: 0,
                  name: 'Đã ngừng',
                },
              ]"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <b-form-group class="required-control">
              <label>Điều kiện hỗ trợ Min(%)</label>
              <b-form-input
                size="sm"
                v-model="mainModel.targetMin"
                placeholder="Nhập điều kiện "
                class="text-right"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group class="required-control">
              <label>Điều kiện hỗ trợ Max(%)</label>
              <b-form-input
                size="sm"
                v-model="mainModel.targetMax"
                placeholder="Nhập điều kiện "
                class="text-right"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group class="required-control">
              <label>Loại chương trình </label>
              <b-form-select
                size="sm"
                class="select-style"
                v-model="mainModel.selectTypeProgram"
                :options="[
                {
                  id: 0,
                  name: '--chọn loại chương trình--',
                },
                {
                  id: 1,
                  name: 'Hotsale',
                },
                {
                  id: 2,
                  name: 'JBP',
                },
              ]"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="5">
            <label>Không áp dụng với chương trình</label>
            <treeselect
              :multiple="true"
              v-model="mainModel.dealSheetIds"
              :async="true"
              :load-options="debouncedLoadOptions"
              placeholder="Nhập tên chương trình"
              noResultsText="Không tìm thấy kết quả"
              :searchable="true"
              valueFormat="object"
            />
          </b-col>
          <b-col cols="1">
            <b-form-group>
              <label>Độ ưu tiên </label>
              <b-form-select
                size="sm"
                class="select-style"
                v-model="mainModel.priority"
                :options="optionsPriority"
                value-field="id"
                text-field="id"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <hr
          class="hr-text"
          data-content="Chỉ tiêu kích hoạt"
          style="font-weight: 600"
        />

        <b-row md-4>
          <b-col md="6">
            <b-form-group label="Chọn cửa hàng">
              <treeselect
                :options="stores"
                :selected="mainModel.storeIds.storeId"
                v-model="mainModel.storeIds"
                :multiple="true"
                :normalizer="normalizer"
                :backspace-removes="false"
                placeholder="Chọn cửa hàng"
                :max-height="200"
                noResultsText="Không tìm thấy kết quả"
                valueFormat="object"
                :clearable="false"
              >
              </treeselect>
            </b-form-group>
          </b-col>
          <b-col
            md="1"
            style="margin-top: 2.25rem"
          >
            <button
              class="btn btn-secondary btn-sm"
              @click="onSelectedStore()"
            >
              <i class="fas fa-arrow-down"></i>
            </button>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8">
            <b-table
              class="col-md-12"
              bordered
              hover
              :fields="childFields"
              :items="listStore"
              :per-page="10"
              :current-page="currentPageStoreTable"
            >
              <template #cell(count)="row">
                {{ row.item.count }}
              </template>
              <template v-slot:cell(targetQuantity)="row">
                <b-input
                  type="text"
                  size="sm"
                  class="input-style text-right"
                  v-model="row.item.targetQuantity"
                  v-mask="mask"
                ></b-input>
              </template>
              <template v-slot:cell(achieved)="row">
                <span :class="row.item.achieved >= row.item.targetQuantity ? 'text-success' : 'text-danger'"> {{
                  convertPrice(row.item.achieved) }}
                </span>
              </template>
              <template v-slot:cell(actions)="row">
                <i
                  class="flaticon2-rubbish-bin-delete-button text-danger"
                  role="button"
                  @click="showDeleteAlert(`${modalTitle} cửa hàng!`, `${modalDesc} cửa hàng ${row.item.name}`, row.item, confirmDeleteStore)"
                >
                </i>
              </template>
            </b-table>
            <b-row>
              <b-col>
                <p
                  class="mt-3 text-dark"
                  style="font-weight: 500"
                >
                  Tổng số:
                  {{ listStore.length }}
                </p>
              </b-col>
              <b-pagination
                class="custom-pagination"
                v-show="listStore.length > 10"
                v-model="currentPageStoreTable"
                :total-rows="listStore.length"
                :per-page="10"
                align="right"
              >
              </b-pagination>
            </b-row>
          </b-col>
        </b-row>
        <div class="my-5"></div>
        <hr
          class="hr-text"
          data-content="Sản phẩm"
          style="font-weight: 600"
        />

        <b-row>
          <b-form-group class="col-md-12 pr-0">
            <label>Tìm sản phẩm:</label>
            <b-col class="row pr-0">
              <b-col
                md="6"
                class="p-0"
              >
                <Autosuggest
                  :model="searchProductFor"
                  :suggestions="optionProducts"
                  placeholder="tên, mã, mã vạch sản phẩm"
                  :limit="10"
                  @select="onSelectedProductFor"
                  @change="onInputChangeProductFor"
                  :disabled="false"
                  suggestionName="productName"
                />
              </b-col>
              <b-col
                md="2"
                class="p-0"
              >
                <b-form-select
                  class="
                    select-style
                    border-left-0
                    rounded-left-0 rounded-right-0
                  "
                  v-model="selectTypeSearchFor"
                  :options="[
                    {
                      id: 1,
                      name: 'Sản phẩm IMEI',
                    },
                    {
                      id: 2,
                      name: 'Sản phẩm',
                    },
                  ]"
                  size="sm"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                  @change="debounceInputFor"
                ></b-form-select>
              </b-col>
              <b-col
                md="2"
                class="p-0"
              >
                <b-form-select
                  class="select-style border-left-0 rounded-left-0"
                  v-model="selectTypeOfProductFor"
                  :options="productTypes"
                  size="sm"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                  @change="debounceInputFor"
                ></b-form-select>
              </b-col>
              <b-col
                md="2"
                class="pr-0"
                v-if="false"
              >
                <div class="d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn btn-secondary font-weight-bolder btn-sm"
                    @click="showModalImportProductFor"
                  >
                    <i
                      style="font-size: 1rem"
                      class="far fa-file-excel"
                    ></i>
                    Nhập từ excel
                  </button>
                </div>
              </b-col>
            </b-col>
          </b-form-group>
        </b-row>
        <b-row>
          <b-col md="12">
            <div>
              <b-table
                bordered
                hover
                class="col-md-12"
                :fields="productfields"
                :items="products"
                :per-page="10"
                :current-page="currentPageProductTable"
              >
                <template #cell(count)="row">
                  {{ row.item.count }}
                </template>
                <template #cell(originalPrice)="row">
                  {{ convertPrice(row.item.originalPrice) }}
                </template>
                <template v-slot:cell(targetQuantity)="row">
                  <b-input
                    type="text"
                    size="sm"
                    class="input-style text-right"
                    v-model="row.item.targetQuantity"
                    v-mask="mask"
                  ></b-input>
                </template>
                <template v-slot:cell(discountAmount)="row">
                  <b-form-input
                    size="sm"
                    v-model="row.item.discountAmount"
                    placeholder="Nhập mức hỗ trợ"
                    v-mask="mask"
                    class="text-right"
                  ></b-form-input>
                </template>
                <template v-slot:cell(achieved)="row">
                  <span v-if="row.item.achieved !== undefined" :class="row.item.achieved >= row.item.targetQuantity ? 'text-success' : 'text-danger'">
                    {{ convertPrice(row.item.achieved) }}
                  </span>
                </template>
                <template v-slot:cell(supportedAmount)="row">
                  <span>
                    {{ convertPrice(row.item.supportedAmount) }}
                  </span>
                </template>
                 <template v-slot:cell(achievedRatioOfAlias)="row">
                  <span v-if="row.item.achievedRatioOfAlias !== undefined" :class="row.item.achievedRatioOfAlias >= mainModel.targetMin ? 'text-success' : 'text-danger'">
                    {{row.item.achievedRatioOfAlias}}%
                  </span>
                </template>
                <template v-slot:cell(imeiDetails)="row">
                  <i
                    class="flaticon-eye"
                    @click="showModal(row.item.imeiDetails)"
                    role="button"
                  ></i>
                </template>
                <template v-slot:cell(actions)="row">
                  <i
                    class="flaticon2-rubbish-bin-delete-button text-danger"
                    role="button"
                    @click="showDeleteAlert(`${modalTitle} sản phẩm!`, `${modalDesc} sản phẩm ${row.item.productName} `, row.item, confirmDeleteProduct)"
                  ></i>
                </template>
              </b-table>
              <b-row>
                <b-modal
                  v-b-modal.modal-lg
                  ref="modal-dealsheet-imeiCode"
                  hide-footer
                  title="Danh sách Imei"
                  id="modal-dealsheet-imeiCode"
                  size="lg"
                >
                  <b-table
                    hover
                    :items="imeiDataRows"
                    :fields="fieldImeis"
                    :per-page="10"
                    :current-page="currentPageImei"
                  >
                    <template #cell(index)="row">
                      {{ row.item.index + 1 }}
                    </template>
                    <template #cell(date)="row">
                      {{ formatDate(row.item.date, DATE_FORMAT) }}
                    </template>
                  </b-table>
                  <b-row>
                    <b-col fixed>
                      <p
                        class="mt-3 text-dark"
                        style="font-weight: 500"
                      >
                        Tổng số:
                        {{ imeiDataRows.length }}
                      </p>
                    </b-col>
                    <b-pagination
                      class="custom-pagination"
                      v-show="imeiDataRows?.length > 10"
                      v-model="currentPageImei"
                      :total-rows="imeiDataRows.length"
                      :per-page="10"
                      align="right"
                    >
                    </b-pagination>
                  </b-row>
                  <b-button
                    style="width: 80px"
                    variant="secondary"
                    size="sm"
                    @click="hideModal()"
                  >
                    <strong>Đóng</strong>
                  </b-button>
                </b-modal>
              </b-row>
              <b-row>
                <b-col>
                  <p
                    class="mt-3 text-dark"
                    style="font-weight: 500"
                  >
                    Tổng số:
                    {{ products.length }}
                  </p>
                </b-col>
                <b-pagination
                  class="custom-pagination"
                  v-show="products.length > 10"
                  v-model="currentPageProductTable"
                  :total-rows="products.length"
                  :per-page="10"
                  align="right"
                >
                </b-pagination>
              </b-row>
            </div>
          </b-col>
          <b-col
            cols="6"
            md="4"
          ></b-col>
        </b-row>
      </template>
      <template v-slot:foot>
        <b-button
          v-if="checkPermission('DEALSHEET_UPDATE')"
          v-bind:style="btnCreate"
          size="sm"
          variant="primary"
          class="font-weight-bolder mr-4"
          @click="onSubmit()"
        >
          Lưu
        </b-button>
        <b-button
          v-bind:style="btnCreate"
          size="sm"
          variant="secondary"
          class="font-weight-bolder"
          @click="onBack"
        >
          Hủy
        </b-button>
      </template>
    </KTCodePreview>
  </div>
</template>
<style lang="scss" scoped>
label {
  font-weight: 500;
}

.success-header,
.alert-header,
.warning-header {
  border: 1px solid transparent;
  border-radius: 0.25rem;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 20px;
  line-height: 2rem;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1.5rem;
}

.warning-header {
  background: #fcf1ef;
  color: #333238;
}

.success-header {
  background: #90d79d;
  color: #ffffff;
}

.right-warning,
.right-alert {
  display: flex;
  flex-direction: column;
}

.warning-header p,
.alert-header p {
  margin-bottom: 0;
}

.warning-header p:first-child {
  font-weight: 500;
  font-size: 1.125rem;
}

.alert-header {
  color: #856404;
  background-color: #fff3cd;
}

.main ::v-deep {
  .input-style {
    border: 1px solid #ced4da;
  }

  input.form-control {
    border: 1px solid #ced4da;
  }

  .muti-basic-custom .rounded {
    border-radius: 0.28rem !important;
  }

  .multi-select-wrapper {
    flex: 1 1 auto;
  }

  .table {
    th,
    td {
      padding: 0.75rem;
      vertical-align: middle;
      border-top: 1px solid #ecf0f3;
    }
  }
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { showDeleteAlert } from '@/utils/sweet-alert2';
import datePicker from 'vue-bootstrap-datetimepicker';
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { getToastInstance } from '@/utils/toastHelper';
import { cloneDeep } from 'lodash';
import debounce from 'debounce';
import {
  TIME_TRIGGER,
  URL_IMPORT_PRODUCT_PROMOTION,
  URL_IMPORT_PRODUCT_FOR_PROMOTION,
} from '@/utils/constants';
import {
  currencyMask,
  makeToastFaile,
  makeToastSuccess,
  convertPrice,
  unMaskPrice,
} from '@/utils/common';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import { v4 } from 'uuid';
import _ from 'lodash';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { DP_CONFIG } from '@/utils/date';
import { DEALSHEET_STATUS } from '@/utils/enum';
import localData from '@/utils/saveDataToLocal';
import { formatSpecificDate, formatDate } from '@/utils/date';
import { DATE_FORMAT } from '@/utils/constants';
const SEARCH_TYPE = {
  PRODUCT: 2,
  CATEGORY: 3,
  BRAND: 4,
};

export default {
  mixins: [validationMixin],
  data() {
    return {
      modalDesc: 'Bạn có muốn xoá',
      modalTitle: 'Xoá ',
      achievementRatio: 0,
      notSupportedAlias: [],
      searchQuery: '',
      options: [1, 2, 3],
      title: '',
      dpConfigs: DP_CONFIG,
      selectedCategory: [],
      optionsPriority: [],
      DATE_FORMAT: DATE_FORMAT,
      childFields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-center',
        },
        {
          key: 'siteCode',
          label: 'Sitecode',
          thStyle: { fontWeight: 600, color: '#181c32', width: '25%' },
          tdClass: 'text-left',
        },
        {
          key: 'name',
          label: 'Tên cửa hàng',
          thStyle: { fontWeight: 600, color: '#181c32', width: '40%' },
          tdClass: 'text-left',
        },

        {
          key: 'targetQuantity',
          label: 'Chỉ tiêu',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          tdClass: 'text-left',
        },
        {
          key: 'achieved',
          label: 'Thực đạt',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          tdClass: 'text-right',
        },
        { key: 'actions', label: '', tdClass: 'text-center' },
      ],
      mainModel: {
        id: null,
        code: '',
        name: '',
        startDate: moment(new Date()).format(DATE_FORMAT),
        endDate: null,
        products: [],
        notApplicablePrograms: [],
        appliedProducts: [],
        isActive: DEALSHEET_STATUS.ACTIVE,
        targetMin: '',
        targetMax: '',
        type: '',
        storeIds: [],
        dealSheetIds: [],
        discountAmount: '',
        isLimit: false,
        limit: 0,
        selectTypeProgram: 0,
        priority: 10,
      },
      productfields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '20%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'productBrandCode',
          label: 'Mã hãng',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '10%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'alias',
          label: 'Alias',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'originalPrice',
          label: 'Giá',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'discountAmount',
          label: 'Mức hỗ trợ',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '10%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'targetQuantity',
          label: 'Chỉ tiêu',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '8%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'achieved',
          label: 'Thực đạt',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '7%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'achievedRatioOfAlias',
          label: 'Tỉ lệ',
          tdClass: 'text-right',
          thStyle: { fontWeight: 600, color: '#181c32', width: '7%' },
        },
        {
          key: 'supportedNumber',
          label: 'SL hỗ trợ',
          tdClass: 'text-right',
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
        },
        {
          key: 'supportedAmount',
          label: 'Thành tiền',
          tdClass: 'text-right',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'imeiDetails',
          label: 'Imei',
          tdClass: 'text-center',
          thStyle: { fontWeight: 600, color: '#181c32', width: '3%' },
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'text-center',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
      ],
      fieldImeis: [
        {
          key: 'index',
          label: 'STT',
          tdClass: 'text-center',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          thClass: 'text-center',
        },
        {
          key: 'imeiCode',
          label: 'Imei',
          tdClass: 'text-center',
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
          thClass: 'text-center',
        },
        {
          key: 'date',
          label: 'Ngày kích hoạt',
          tdClass: 'text-center',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
          thClass: 'text-center',
        },
        {
          key: 'siteCode',
          label: 'SiteCode',
          tdClass: 'text-center',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
          thClass: 'text-center',
        },
        {
          key: 'sourceFrom',
          label: 'Nguồn',
          tdClass: 'text-center',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
          thClass: 'text-center',
        },
      ],
      filteredProducts: [],
      products: [],
      searchProductFor: '',
      categories: [],
      isSearching: false,
      productTypes: [
        {
          id: 0,
          name: 'Tất cả sản phẩm',
        },
        {
          id: 1,
          name: 'Sản phẩm cha',
        },
        {
          id: 2,
          name: 'Sản phẩm con',
        },
        {
          id: 3,
          name: 'Sản phẩm độc lập',
        },
        {
          id: 4,
          name: 'Sản phẩm cha + độc lập',
        },
        {
          id: 5,
          name: 'Sản phẩm con + độc lập',
        },
      ],
      selectTypeSearchFor: 1,
      currentPageImei: 1,
      selectTypeOfProductFor: 0,
      importItems: [],
      file: null,
      excellist: [],
      fieldImport: [
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
        },
      ],
      importItemFor: [],
      fileFor: null,
      excellistFor: [],
      currentPageFor: 1,
      fieldImportFor: [
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
        },
      ],
      urlExcel: URL_IMPORT_PRODUCT_PROMOTION,
      urlExcelFor: URL_IMPORT_PRODUCT_FOR_PROMOTION,
      currentPageProductTable: 1,
      currentPageStoreTable: 1,
      mask: currencyMask,
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
      isBusy: false,
      stores: [],
      listStore: [],
      searchProduct: '',
      filteredItems: [],
      btnCreate: {
        fontWeight: '600!important',
        'margin-right': '-10px',
        width: '70px',
      },
      selectTypeSearch: 2,
      selectTypeOfProduct: 0,
      listAmountType: [
        {
          id: 1,
          name: '$',
        },
        {
          id: 2,
          name: '%',
        },
      ],
      imeiDataRows: [],
    };
  },
  components: {
    KTCodePreview,
    datePicker,
    Treeselect,
    Autosuggest,
  },
  watch: {},
  computed: {
    optionProducts() {
      return [...cloneDeep(this.filteredProducts)];
    },
    optionItems() {
      return [...cloneDeep(this.filteredItems)];
    },
    itemTypeOptions() {
      if (this.selectTypeSearch === SEARCH_TYPE.PRODUCT) {
        return [
          {
            id: 0,
            name: 'Tất cả sản phẩm',
          },
          {
            id: 1,
            name: 'Sản phẩm cha',
          },
          {
            id: 2,
            name: 'Sản phẩm con',
          },
          {
            id: 3,
            name: 'Sản phẩm độc lập',
          },
          {
            id: 4,
            name: 'Sản phẩm cha + độc lập',
          },
          {
            id: 5,
            name: 'Sản phẩm con + độc lập',
          },
        ];
      } else if (this.selectTypeSearch === SEARCH_TYPE.CATEGORY) {
        return [
          {
            id: 0,
            name: 'Tất cả nhóm sản phẩm',
          },
          {
            id: 1,
            name: 'Nhóm sản phẩm cha',
          },
          {
            id: 2,
            name: 'Nhóm sản phẩm con',
          },
        ];
      } else {
        return [
          {
            id: 0,
            name: 'Tất cả thương hiệu',
          },
        ];
      }
    },
  },
  mounted() {
    this.title = 'Chi tiết dealsheet';
    if (this.$route.query.id) {
      this.title = 'Cập nhật dealsheet ';
      this.mainModel.id = this.$route.query.id;
      this.getInfoById();
    }
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
    for (let i = 1; i <= 10; i++) {
      this.optionsPriority.push(i);
    }
  },
  created() {
    this.getCategories();
    this.fetchStore();
  },
  methods: {
    showDeleteAlert,
    formatSpecificDate,
    formatDate,
    showModal(imeiDetails) {
    const sourceFromKey = {
        bill: 'Số bán thực tế',
        active: 'Kích hoạt Imei',
        wholesale: 'Số bán sỉ',
    }
      this.imeiDataRows = imeiDetails.map((item,index)=>{
        return{
          ...item,
          index,
          sourceFrom: sourceFromKey[item.sourceFrom]
        }
      });
      this.$refs[`modal-dealsheet-imeiCode`].show();
    },
    hideModal() {
      this.$refs[`modal-dealsheet-imeiCode`].hide();
    },
    ...getToastInstance(),
    convertPrice,
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    getCategories() {
      ApiService.query('category')
        .then(({ data }) => {
          if (!data.status) {
            return makeToastFaile('Lấy dữ liệu danh mục thất bại!');
          }
          this.categories = data.data?.list_caterogy;
        })
        .catch((err) => {
          const message = err.response.data.message || "Lỗi";
          makeToastFaile(message);
        });
    },
    confirmDeleteStore(deleteItem) {
      this.listStore = _.reject(this.listStore, (item) => {
        return item.id == deleteItem.id;
      });
      this.listStore.forEach((element, index) => {
        element.index = index + 1;
      });
    },
    confirmDeleteProduct(deleteItem) {
      this.products = _.reject(this.products, (item) => {
        return item.productId == deleteItem.productId;
      });
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      this.filteredProducts = [];
      const params = {
        searchProduct: textSearch ? textSearch.trim() : null,
        typeSearch: this.selectTypeSearchFor,
        typeOfProduct: this.selectTypeOfProductFor,
      };
      ApiService.query(`productSearch/search-by-type`, {
        params,
      }).then((response) => {
        this.filteredProducts = response.data?.data;
        this.isSearching = false;
      });
    },
    debouncedLoadOptions({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchQueryChange(searchQuery, callback);
      }
    },
    handleSearchQueryChange: debounce(async function (searchQuery, callback) {
      this.options = [];
      this.searchQuery = searchQuery;
      const dealsheetList = (
        await ApiService.query(`v2/deal-sheets?searchText=${this.searchQuery}`)
      ).data?.data.dataset;
      if (dealsheetList.length > 0) {
        this.options = dealsheetList.map((item) => ({
          id: item.id,
          code: item.code,
          label: item.name,
        }));
        callback(null, this.options);
      }
    }, TIME_TRIGGER),
    onSelectedProductFor(option) {
      this.searchProductFor = '';
      const index = this.products.findIndex((product) => {
        return product.productId == option.item.productId;
      });
      if (index > -1) {
        return makeToastFaile(`${option.item.productName} đã tồn tại`);
      }
      let count = this.products.length + 1;
      this.products.push({
        id: v4(),
        productId: option.item.productId,
        targetQuantity: unMaskPrice(option.item.targetQuantity) || 0,
        discountAmount: unMaskPrice(option.item.discountAmount) || 0,
        productName: option.item.productName,
        productCode: option.item.productCode,
        productBrandCode: option.item?.productBrandCode || '',
        alias: option.item?.alias || '',
        originalPrice: option.item.originalPrice,
        count: count,
      });
    },
    onInputChangeProductFor(text) {
      this.searchProductFor = text;
      this.debounceInputFor();
    },
    onInputChangeSearch(text) {
      this.searchProduct = text;
      this.debounceInputSearch();
    },
    onBack: function () {
      history.back();
    },
    initPayload() {
      const params = cloneDeep(this.mainModel);
      params.storeIds = _.map(params.storeIds, 'id').join();
      const items = this.listStore.map((item) => {
        return {
          storeId: item.storeId,
          targetQuantity: unMaskPrice(item.targetQuantity),
        };
      });
      const details = this.products.map((item) => {
        return {
          productId: item.productId,
          targetQuantity: unMaskPrice(item.targetQuantity) || 0,
          discountAmount: unMaskPrice(item.discountAmount) || 0,
          productName: item.productName,
          productBrandCode: item?.productBrandCode || '',
          alias: item?.alias || '',
        };
      });
      return {
        id: params.id,
        name: params.name.trim(),
        code: params.code.trim(),
        targetMin: params.targetMin,
        targetMax: params.targetMax,
        storeIds: params.storeIds ? params.storeIds : '-1',
        isActive: params.isActive,
        priority: params.priority,
        type: params.selectTypeProgram,
        startDate: params.startDate,
        endDate: params.endDate,
        stores: items,
        details: details,
        categories: this.selectedCategory,
        isLimit: params.isLimit,
        limit: params.limit ? unMaskPrice(params.limit) : 0,
        discountAmount: params.discountAmount
          ? unMaskPrice(params.discountAmount)
          : 0,
        notApplicablePrograms: this.mainModel.dealSheetIds.map(
          (item) => item.code,
        ),
      };
    },
    onSubmit: async function () {
      this.isBusy = true;
      if (!this.validate()) return false;
      const payload = this.initPayload();
      if (!payload.id) {
        this.onCreate(payload);
      } else {
        this.onUpdate(payload);
      }
    },
    validate: function () {
      const regexNumber = /^[1-9]\d*$/;
      if (!this.mainModel.name || !this.mainModel.name.trim()) {
        makeToastFaile('Vui lòng nhập tên chương trình!');
        return;
      }
      if (!this.mainModel.code || !this.mainModel.code.trim()) {
        makeToastFaile('Vui lòng nhập mã chương trình!');
        return;
      }
      if (!this.mainModel.startDate || !this.mainModel.endDate) {
        makeToastFaile('Vui lòng nhập thời gian cho dealsheet!');
        return;
      }
      const formattedStartDate = formatSpecificDate(
        this.mainModel.startDate,
        DATE_FORMAT,
      );
      const formattedEndDate = formatSpecificDate(
        this.mainModel.endDate,
        DATE_FORMAT,
      );

      if (!moment(formattedEndDate).isAfter(formattedStartDate)) {
        makeToastFaile('Thời gian không hợp lệ!');
        return;
      }
      if (!this.mainModel.targetMin) {
        makeToastFaile('Vui lòng nhập điều kiện min!');
        return;
      }
      if (!regexNumber.test(this.mainModel.targetMin)) {
        makeToastFaile('Điều kiện min không hợp lệ!');
        return;
      }
      if (!this.mainModel.targetMax) {
        makeToastFaile('Vui lòng nhập điều kiện max!');
        return;
      }
      if (!regexNumber.test(this.mainModel.targetMax)) {
        makeToastFaile('Điều kiện đạt max không hợp lệ!');
        return;
      }
      if (Number(this.mainModel.targetMax) < Number(this.mainModel.targetMin)) {
        makeToastFaile('Điều min không được lớn hơn điều kiện max!');
        return;
      }
      const filterArray = this.listStore.filter((item) => !item.targetQuantity);
      if (filterArray && filterArray.length) {
        for (let item of filterArray) {
          makeToastFaile(`Vui lòng nhập chỉ tiêu cho cửa hàng ${item.name}!`);
        }
        return;
      }
      if (this.products.length < 1) {
        makeToastFaile('Vui lòng chọn sản phẩm!');
        return;
      }
      for (let item of this.products) {
        if (!item.productBrandCode || !item.productBrandCode.trim()) {
          makeToastFaile(`Mã hãng cho ${item.productName} không được trống!`);
          makeToastFaile(`Vui lòng nhập mã hãng trước khi thêm vào cấu hình`);
          return;
        }
        if (!item.alias || !item.alias.trim()) {
          makeToastFaile(`Alias cho ${item.productName} không được trống!`);
          makeToastFaile(`Vui lòng nhập alias trước khi thêm vào cấu hình`);
          return;
        }
        if (!item.discountAmount || item.discountAmount <= 0) {
          makeToastFaile(`Mức hỗ trợ cho ${item.productName} không hợp lệ!`);
          return;
        }
        if (!item.targetQuantity || item.targetQuantity <= 0) {
          makeToastFaile(`Chỉ tiêu cho ${item.productName} không hợp lệ!`);
          return;
        }
      }
      return true;
    },
    onCreate(payload) {
      ApiService.post('v2/deal-sheets', payload)
        .then((response) => {
          this.isBusy = false;
          const { status, message } = response.data;
          if (status == 200) {
            makeToastSuccess(message);
            history.back();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          this.isBusy = false;
          const message = err.response.data.message || 'Lỗi';
          makeToastFaile(message);
        });
    },
    onUpdate(payload) {
      ApiService.put('/v2/deal-sheets', payload)
        .then((response) => {
          this.isBusy = false;
          const { status, message } = response.data;
          if (status == 200) {
            makeToastSuccess(message);
            history.back();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          this.isBusy = false;
          const message = err.response.data.message || 'Lỗi';
          makeToastFaile(message);
        });
    },
    debounceInputFor: debounce(function () {
      if (!this.isSearching) {
        let textSearch = this.searchProductFor;
        this.fetchProduct(textSearch);
      }
    }, TIME_TRIGGER),
    showModalImportProductFor() {
      this.importItems = [];
      this.excellist = [];
      this.$refs['import-product-for-modal'].show();
    },
    fetchStore: async function () {
      this.stores = [];
      ApiService.get(`stores/getStores`).then((response) => {
        const listStore = response.data?.data;
        this.stores = listStore.map((element) => {
          return {
            ...element,
            storeId: element.id,
            name: element.name,
            siteCode: element.siteCode,
          };
        });
      });
    },
    debounceInputSearch: debounce(function () {
      if (!this.isSearching) {
        const textSearch = this.searchProduct
          ? this.searchProduct.trim()
          : null;
        if (!textSearch) {
          return;
        }
        if (this.selectTypeSearch === SEARCH_TYPE.BRAND) {
          this.searchBrand(textSearch);
        } else {
          this.searchItem(textSearch);
        }
      }
    }, TIME_TRIGGER),
    onSelectedStore() {
      if (!this.listStore || !this.listStore.length) {
        this.listStore = this.mainModel.storeIds;
        return;
      }
      const newStore = this.mainModel.storeIds.filter((item) => {
        return !this.listStore.some((store) => store.storeId === item.storeId);
      });
      const result = this.listStore.concat(newStore);
      this.listStore = result.map((item, index) => {
        return { ...item, count: ++index };
      });
    },
    searchItem(textSearch) {
      this.isSearching = true;
      this.filteredItems = [];
      const params = {
        searchProduct: textSearch,
        typeSearch: this.selectTypeSearch,
        typeOfProduct: this.selectTypeOfProduct,
      };
      ApiService.query(`productSearch/search-by-product-category`, {
        params,
      }).then((response) => {
        this.filteredItems = response.data?.data;
        this.isSearching = false;
      });
    },
    async getInfoById() {
      try {
        const result = (
          await ApiService.get(`v2/deal-sheets/${this.mainModel.id}`)
        ).data?.data;
        this.mainModel.isActive = result.isActive ? 1 : 0;
        this.mainModel.selectTypeProgram = result.type;
        this.mainModel.code = result.code || '';
        this.mainModel.name = result.name || '';
        this.mainModel.targetMin = result.targetMin || '';
        this.mainModel.targetMax = result.targetMax || '';
        this.mainModel.type = result.type;
        this.mainModel.dealSheetIds = result.notApplicablePrograms.map(
          (item) => ({
            id: item.id,
            code: item.code,
            label: item.name,
          }),
        );
        this.mainModel.priority = result.priority;
        this.mainModel.startDate = result.startDate;
        this.mainModel.endDate = result.endDate;
        this.listStore = result.stores;
        this.listStore = this.listStore.map((item, index) => {
          return { ...item, count: ++index };
        });
        this.mainModel.storeIds = result.stores;
        this.products = result.details;
        this.products = this.products.map((item, index) => {
          if(item.notSupportedAlias){
            this.notSupportedAlias.push(item.notSupportedAlias)
          }
          return { ...item, count: ++index };
        });
        this.notSupportedAlias = [...new Set(this.notSupportedAlias)]
        this.achievementRatio = result.achievementRatio || 0;
      } catch (err) {
        const message = err.response.data.message || 'Lỗi';
        makeToastFaile(message);
      }
    },
    calSumDiscountRate() {
      this.listStore.forEach(
        (x) => (x.discountRate = parseInt(x.discountRate)),
      );
      return _.sumBy(this.listStore, 'discountRate');
    },
    searchBrand: function (textSearch) {
      this.listBrand = [];
      const param = {
        page: 1,
        pageSize: 10,
        name: textSearch,
      };
      const paramQuery = {
        params: {
          param,
        },
      };
      ApiService.query('brands', paramQuery).then((response) => {
        this.filteredItems = response.data?.brands;
      });
    },
    mapReferenceTypeWithSearch(searchType) {
      switch (searchType) {
        case SEARCH_TYPE.PRODUCT:
          return 1;
        case SEARCH_TYPE.CATEGORY:
          return 2;
        case SEARCH_TYPE.BRAND:
          return 3;
      }
    },
  },
};
</script>

